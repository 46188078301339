import { ContetBanner, DummyBanner } from "../style";
import Script from "next/script";
import { useState, useEffect } from "react";
import { BannerCollapse } from "@/shared/ContentBanner";
import { sizeBanner } from "@/shared/DFP/Home/mapping";

const BannerHorizontal = (props) => {
  const { isPreview, slotId } = props;
  const [isClient, setIsClient] = useState(false);

  const adsPathModificated = "clarin/home";

  const filterSizeBanner = (slotId, sizeBanner) => {
    let map = sizeBanner[Object.keys(sizeBanner).filter(item => (item == slotId))]
    return map != undefined ? map : "";
  }

  if (isPreview) {
    return (
      <DummyBanner>
        <p className={`banner-reserved-space`}>Banner {slotId}</p>
      </DummyBanner>
    );
  }

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (filterSizeBanner(slotId, sizeBanner) == "") {
    return isClient ? (<BannerCollapse>
      <ContetBanner className="bannerSRA" id={`div-gpt-ad-${slotId}`} />
    </BannerCollapse>) : (
      <></>
    )
  }

  let mapping = filterSizeBanner(slotId, sizeBanner);
  const sizeMapping = mapping.mapping
    ? mapping.mapping
      .map((entry) => {
        const viewportString = JSON.stringify(entry.viewport);
        const msizesString = JSON.stringify(entry.msizes);
        return `.addSize(${viewportString}, ${msizesString})`;
      })
      .join('\n')
    : "";
  const sizeString = JSON.stringify(mapping.sizes);

  //componente para la carga de banners en Lila
  return isClient ? (
    <BannerCollapse>
      <ContetBanner className="bannerHLazy" id={`div-gpt-ad-${slotId}`}>
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  window.googletag = window.googletag || {cmd: []};
                  googletag.cmd.push(function() {
                    
                    let addSize = (googletag.sizeMapping()
                    ${sizeMapping}
                    .build());
                    
                    let targetSlot = googletag.defineSlot('/242767168/${adsPathModificated}/${slotId}', ${sizeString} ,'div-gpt-ad-${slotId}')
                      .addService(googletag.pubads())
                      .defineSizeMapping(addSize);
                    googletag.pubads().enableLazyLoad({
                      fetchMarginPercent: 50,
                      renderMarginPercent: 25,
                      mobileScaling: 0.5
                    });
                    googletag.enableServices();
                    googletag.display('div-gpt-ad-${slotId}');
                    googletag.pubads().refresh([targetSlot]);
                    googletag.pubads().addEventListener('slotRenderEnded', function(event) {
                      event.slot.getSlotElementId()
                    });
                    googletag.enableServices();
                  }); 
                `,
          }}
        />
      </ContetBanner>
    </BannerCollapse>
  ) : (
    <></>
  );
};

export default BannerHorizontal;
